<template>
  <div>
    <PageTitlebar title="Add New User">
      <template v-slot:top>
        <Link
          :to="{ name: 'AdvertiserUserAccessOverview' }"
          label="Back to User Access"
          icon="sym_r_chevron_left"
          class="q-mb-sm"
        />
      </template>
    </PageTitlebar>

    <UserAccountForm
      type="create"
      :submitting="submitting"
      @submit="createUser"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import Link from "@/components/UI/Link";
import UserAccountForm from "@/components/Advertiser/AdvertiserUserAccountForm.vue";

export default {
  name: "AdvertiserUserAccountCreator",
  components: {
    UserAccountForm,
    PageTitlebar,
    Link
  },
  data() {
    return {
      submitting: false
    };
  },
  mounted() {},
  methods: {
    createUser(formData) {
      this.submitting = true;

      let createParams = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        type: formData.type
      };

      if (createParams.type == "regular") {
        createParams.advertiser_account_ids = formData.advertiserAccountIds;
      }

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "create",
          params: createParams
        })
        .then(res => {
          if (!res.id) {
            this.triggerActionOutcomeAlert("create", false);
            this.submitting = false;
          } else {
            this.$store
              .dispatch("users/POST_REQUEST", {
                endpoint: "send-user-verification-email",
                params: {
                  email: formData.email
                }
              })
              .then(() => {
                this.triggerActionOutcomeAlert("create", true);
                this.submitting = false;
                this.$router.push({ name: "AdvertiserUserAccessOverview" });
              });
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "create",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("create", false);
          }
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
